export default [
  {
    title: 'لوحة التحكم',
    route: '/',
    icon: 'UserIcon',
  },
  {
    title: 'المدراء',
    route: 'admin',
    icon: 'UserIcon',
  },
  {
    title: 'العملاء',
    route: 'customer',
    icon: 'UserIcon',
  },
  {
    title: 'مزودي الخدمات',
    route: 'provider',
    icon: 'UserIcon',
  },
  {
    title: 'ملف مزودي الخدمات',
    route: 'providerProfile',
    icon: 'UserIcon',
  },
  
  {
    title: 'الخدمات',
    route: 'service',
    icon: 'UserIcon',
  },
  {
    title: 'اللافتات',
    route: 'banner',
    icon: 'UserIcon',
  },
  {
    title: 'الطلبات',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
]
